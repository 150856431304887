@import '../../../global';

.FormTextarea {
  @extend .input-text;
  padding: 15px 20px 0;
  border-radius: 18px;
  height: 91px;
  flex-grow: 1;
  resize: none;

  &:focus {
    outline: none;
  }

  border: 1px solid #979797;
}
