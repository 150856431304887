@import '../../../global';

.AdminPageWallets {
  &__address {
    margin-top: 20px;
  }

  &__collections {
    margin-top: 20px;

    &__collection {
      padding: 20px;
      background-color: $gray2;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      max-width: 685px;
      color: #1c222d;

      &.italic {
        font-family: $italic;
        color: rgba(28,34,45,0.33);
        justify-content: center;
      }

      &__header {
        font-family: $bold;
        margin-bottom: 10px;
        font-size: 12px;
        color: black;
      }

      &__description {
        white-space: pre-line;
        @include mobile {
          max-width: 60vw;
          word-break: break-word;
        }

        font-family: $medium;

        -webkit-touch-callout: text; /* iOS Safari */
        -webkit-user-select: text; /* Safari */
        -khtml-user-select: text; /* Konqueror HTML */
        -moz-user-select: text; /* Old versions of Firefox */
        -ms-user-select: text; /* Internet Explorer/Edge */
        user-select: text; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
      }
      &__remove {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
