@import '../../global';

.AdminPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__pincode {
    background-color: #ecedee;
    max-width: calc(100vw - 48px);
    width: 706px;
    padding: 14px 20px 16px 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    color: black;
    font-size: 12px;
    font-family: $bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main {
    background-color: #f9f9f9;
    max-width: calc(100vw - 48px);
    width: 740px;
  }

  &__tabs {
    background-color: #f0f0f0;
    font-family: $bold;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    height: 48px;
    display: flex;
    justify-content: space-between;

    @include mobile {
      justify-content: space-evenly;
    }
  }

  &__tab {
    padding: 15px 10px 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid transparent;
    font-size: 12px;
    flex-grow: 1;
    width: 33.3%;
    color: $black1;

    @include mobile {
      width: initial;
      max-width: 40%;
    }

    &.active {
      border-bottom: 4px solid $black1;
    }
  }

  &__body {
    padding: 18px 36px 36px;

    @include mobile {
      padding: 18px 16px 36px;
    }
  }

  &__collector-creator-toggle {
    display: flex;
    justify-content: space-between;
    padding: 3px 3px;
    background-color: #f0f0f0;
    border-radius: 19px;
    max-width: 100%;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 24px;
      font-size: 12px;
      cursor: pointer;
      color: $black1;
      width: 50%;

      &.active {
        background-color: $gray1;
        color: white;
        font-family: $bold;
        border-radius: 19px;
      }
    }
  }
}
