.NFTsList {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 36px);
  margin-right: -36px;

  a {
    margin-right: 36px;
    margin-bottom: 36px;
  }

  &--collapsed {
    min-height: initial !important;
    margin-top: 36px !important;

    > * {
      display: none;
    }
  }
}
