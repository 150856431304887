@import '../../../global';

.AdminPageNFTs {
  &__overlay {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    div {
      padding: 10px;
      background-color: white;
      border-radius: 5px;
      color: black;
    }
  }

  &__editIcon {
    cursor: pointer;
  }

  &__collection {
    display: flex;
    align-items: center;
    white-space: nowrap;

    span:first-child {
      position: relative;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 34px);
    }

    &--withBrackets {
      span:first-child {
        padding: 0 4px;
        text-transform: capitalize;

        &::before {
          position: absolute;
          left: 0;
          content: '(';
        }
        &::after {
          position: absolute;
          right: 0;
          content: ')';
        }
      }
    }

    span:last-child {
      cursor: pointer;
      text-decoration: underline;
      color: #4b5464;
      margin-left: 4px;
    }
  }

  &__hideDesktop {
    display: none;

    @include mobile {
      display: flex;
    }
  }

  &__hideMobile {
    display: flex;

    span:last-child {
      cursor: pointer;
      text-decoration: underline;
      color: #4b5464;
      margin-left: 4px;
    }

    @include mobile {
      display: none;
    }
  }
}
