@import '../../../global';

.AdminPageCollections {
  &__list {
    margin-top: 0px;

    @include mobile {
      margin-top: 0px;
    }
  }

  &__subtext {
    color: #8A8D93;
    font-size: 10px;
    margin-top: 18px;
    letter-spacing: 0.33px;
    display: flex;
    justify-content: center;
  }

  &__item-container {
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }

  &__item {
    padding: 16px;
    background-color: $gray2;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    max-width: 685px;
    color: $black1;

    &.italic {
      font-family: $italic;
      color: rgba(28,34,45,0.33);
      justify-content: center;
    }

    &.margin-top {
      margin-top: 18px;
    }
  }

  &__upDown {
    display: flex;
    flex-direction: column;
    margin-top: 2px;

    img {
      margin-bottom: 6px;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: initial;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;

    &--disabled {
      opacity: 0.5;
    }

    @include mobile {
      margin-bottom: 4px;
    }
  }

  &__name {
    @extend .table-bold-text;
    display: flex;
    align-items: center;

    span {
      @extend .table-default-text;
      text-decoration: underline;
      cursor: pointer;
      color: #4b5464;
      margin-left: 4px;
    }

    a {
      white-space: nowrap;
      max-width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    @include mobile {
      flex-direction: column;
      max-width: calc(100vw - 270px);
      align-items: flex-start;

      span {
        margin-left: -2px;
        margin-top: 2px;
      }
    }
  }

  &__description {
    @extend .table-default-text;
    white-space: pre-line;
    max-width: 406px;

    -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Old versions of Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
  }
  &__icons {
    display: flex;
    align-items: flex-start;

    @include mobile {
      margin-top: -12px;
    }

    div {
      display: flex;
      align-items: center;
    }
  }
  &__remove {
    cursor: pointer;
    margin-left: 12px;

    img {
      height: 16px;
    }
  }
  &__organize {
    cursor: pointer;
    margin-left: 12px;

    img {
      height: 15px;
    }
  }
}
