@import '../../../../global';

.AdminPageCollectionsOrganizeModal__list {
  background-color: $gray2;
  overflow-y: scroll;

  .AdminPageCollectionsOrganizeModalNFT {
    display: flex;
    align-items: center;
    padding: 14px;
    height: 94px;
    box-sizing: border-box;

    &:nth-child(2n) {
      background-color: #f6f6f6;
    }

    &__upDown {
      display: flex;
      flex-direction: column;
      margin-right: 14px;
      justify-content: space-between;
      height: 100%;

      img {
        cursor: pointer;
        height: 30px;
        width: 30px;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: initial;
        }
      }
    }

    &__desc {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      overflow: hidden;
    }

    &__name {
      font-family: $bold;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 17px;
      letter-spacing: 0.6px;
      font-size: 12px;
      color: $black1;
      display: inline-block;
    }

    &__address {
      line-height: 17px;
      letter-spacing: 0.33px;
      font-size: 12px;
      color: $black1;
    }
  }
}
