@import '../../../../global';

.AdminPageNFTsAddToCollectionModal__list {
  margin-bottom: 6px;
  padding: 0 8px;
  overflow-y: scroll;

  label {
    margin: 14px 8px 12px;
  }
}
