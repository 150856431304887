@import '../../global';

.NFTsTable {
  &__header-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__header-button {
    cursor: pointer;

    &--disabled {
      pointer-events: none;
      opacity: 0.33;
    }
  }

  .FormCheckbox {
    width: 14px;
  }

  th {
    background-color: #dddddf;
    font-family: $bold;
    font-size: 11px;
    letter-spacing: 0.33px;
    line-height: 17px;
    height: 36px;
  }

  tbody {
    tr {
      height: 78px;

      &:nth-child(2n + 1) {
        background: #ecedee;
      }

      &:nth-child(2n) {
        background: #ecedee54;
      }

      @include mobile {
        height: 90px;
      }
    }

    td {
      @extend .table-default-text;

      &:nth-child(3) {
        div {
          display: flex;
          flex-direction: column;
        }

        a {
          display: flex;

          @include mobile {
            margin-bottom: 1px;
          }

          > span {
            @extend .table-bold-text;
          }
        }

        a > span,
        > span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 100%;
          display: inline-block;
        }
      }
    }
  }

  th,
  td {
    box-sizing: border-box;
    border-bottom: 0;

    &:nth-child(1) {
      width: 46px;
      padding: 0 16px;
    }

    &:nth-child(2) {
      width: 48px;
      padding: 0;
    }

    &:nth-child(3) {
      padding-left: 16px;
      padding-right: 0;
      min-width: 158px;
      max-width: 158px;

      @include mobile {
        min-width: calc(100vw - 300px);
        max-width: calc(100vw - 300px);
      }
    }

    &:nth-child(4) {
      padding-left: 16px;
      padding-right: 0;
      min-width: 104px;
      max-width: 104px;

      @include mobile {
        min-width: 46px;
        max-width: 46px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 17px;

          img {
            margin-right: 2px;
            margin-top: 7px;
          }
        }
      }
    }

    &:nth-child(5) {
      padding-left: 16px;
      padding-right: 0;
      min-width: 145px;
      max-width: 145px;
    }

    &:nth-child(6) {
      padding-left: 0;
      padding-right: 0;
      min-width: 93px;
      max-width: 93px;
      text-align: center;

      > .MuiSwitch-root {
        margin-right: 16px;

        @include mobile {
          margin-right: 0;
          margin-top: 0;
        }
      }
    }

    &:nth-child(7) {
      padding-right: 16px;
      text-align: center;
    }
  }

  &--disabled {
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      opacity: 0.5;

      img,
      video {
        filter: grayscale(100);
      }
    }

    @include mobile {
      td:nth-child(4) {
        opacity: 1 !important;
      }
    }
  }

  .MuiTableCell-root {
    padding: 6px;
    max-width: 125px;
    color: $black1;

    .MuiTableSortLabel-icon {
      color: $black1 !important;
      margin: 0 !important;
    }
  }

  &__hideDesktop {
    display: none !important;

    @include mobile {
      display: table-cell !important;
    }
  }

  &__hideMobile {
    display: table-cell !important;

    @include mobile {
      display: none !important;
    }
  }
}
