@import '../../global';
@import '../../styles/form';

.header, .footer {
  display: none;
}

.ReactModal__Content {
  margin-top: 120px;
  padding: 30px;
  .modal {
    &.preview {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.form {
  padding-left: 0 !important;

  &__header {
    font-size: 28px;
    padding-bottom: 10px;
    font-family: $bold-italic;
  }

  &__button-container {
    display: flex;
    align-items: center;

    .button {
      padding: 12px 17px;
    }

    .cancel {
      margin-left: 20px;
    }
  }

  &__input-container {
    padding-bottom: 10px;

    &__header {
      font-size: 12px;
      font-family: Poppins-Bold;
      text-transform: uppercase;
      letter-spacing: 2.57px;
      padding-bottom: 5px;
    }
    input,
    textarea {
      padding-left: 20px;
      border-radius: 18px;
      height: 36px;
      width: 300px;
      &:focus {
        outline: none;
      }
      max-width: 25vw;
      border: 1px solid #979797;

      @include mobile {
        max-width: 60vw;
      }
    }
    textarea {
      height: 85px;

      padding-top: 10px;
    }
    .button {
      &.green {
        letter-spacing: 2.57px;
      }
    }
  }

  &__subtext {
    font-size: 12px;
    letter-spacing: 0.5px;
    padding: 0 14px;

    &.link {
      margin-top: 12px;
      text-decoration: underline;
    }
  }

  textarea {
    font-family: $default;
    font-size: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    &.add-modal {
      margin-bottom: 5px;
      margin-left: 12px;
      padding-bottom: 0;
    }

    img, video {
      width: 50px;
      height: 50px;
      margin-right: 18px;
    }
    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 11px;
      letter-spacing: 0.5px;

      &.bold {
        font-family: $bold;
        letter-spacing: 0.5px;
      }
    }
  }
  &__item-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    font-size: 12px;
    letter-spacing: 0.5px;
    flex-direction: column;

    &__container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__header {
      font-family: $bold;
      color: #1C222D;
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
  }
  &__graybox {
    background-color: #ECEDEE;
    font-family: $medium;
    font-size: 12px;
    padding: 16px;
    margin-bottom: 16px;
  }
}

.button {
  max-width: 100%;
}

.purchase {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 36px;

  &__close {
    font-size: 12px;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }

  &__nft-container {
    display: flex;
    align-items: center;
    margin-top: 8px;

    &__image {
      margin-right: 14px;
      img {
        height: 48px;
        width: 48px;
      }
    }

    &__name-container {
      font-size: 12px;
      letter-spacing: 0.5px;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .button-container {
    min-height: 50vh;
    display: flex;
    align-items: center;
  }

  .button {
    margin-top: 10px;
    padding: 12px 24px;
    width: 173px;
    &.floating {
      position: fixed;
      bottom: 24px;
      right: 24px;
      width: initial;
      width: 40px;
      height: 40px;
      img {
        width: 30px;
        margin-right: 2px;
      }
    }
    &.small {
      width: 107px;
    }
    &.initial {
      border-radius: 0px;
      position: fixed;
      bottom: 116px;
      width: 277px;
      right: 0px;
      max-width: 295px;
      height: 44px;
      text-align: left;
      justify-content: flex-start;
      padding-left: 28px;
    }
  }

  &__connected {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;

    &__container {
      display: flex;
      flex-direction: column;

      &__header {
        font-family: $bold;
        font-size: 12px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
      }
      &__balance {
        font-size: 12px;
        letter-spacing: 0.5px;
        margin-bottom: 18px;
      }
    }

    &__address {
      background-color: #333A48;
      font-size: 12px;
      letter-spacing: 0.5px;
      padding: 13px 18px 18px;
      margin-bottom: 24px;
      width: 90%;
    }

    &__qr, &__url {
      width: 235px;
      margin: 0 auto 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #333A48;
      padding: 12px;
      margin-bottom: 24px;

      video {
        height: 235px;
        width: 235px;
      }

      input {
        font-family: 'Poppins';
        padding-left: 20px;
        border-radius: 18px;
        height: 36px;
        width: 300px;
        &:focus {
          outline:none;
        }
        max-width: 25vw;
        border: 1px solid #979797;
      }
    }
  }

  &__asset-container {
    background-color: #333A48;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 34px 18px;
    width: 193px;
    max-width: 193px;
    margin: 0 auto 24px;

    div {
      width: inherit;
    }

    img {
      width: 192px;
      height: 192px;
    }

    .button {
      margin-bottom: 12px;
    }
  }
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #000000;
  background: -moz-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: -webkit-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: -o-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: -ms-linear-gradient(left, #000000 10%, rgba(0,0,0, 0) 42%);
  background: linear-gradient(to right, #000000 10%, rgba(0,0,0, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #000000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
