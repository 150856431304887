@import '../../../../global';

.AdminPageNFTsNotesModalDetails {
  @extend .box;
  margin-bottom: 24px;

  &__data {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    overflow: hidden;
  }

  &__name {
    font-size: 12px;
    font-family: $bold;
    line-height: 16px;
    letter-spacing: 0.6px;
    margin-bottom: 8px;
  }

  &__values {
    display: flex;

    &-item {
      display: flex;
      flex-direction: column;
      margin-right: 18px;
      font-size: 11px;
      line-height: 17px;
      letter-spacing: 0.33px;
      flex-shrink: 0;
      margin-bottom: 6px;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
        flex-shrink: 1;
      }

      &-header {
        font-family: $bold;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @include mobile {
      flex-wrap: wrap;

      &-item {
        width: 100%;
      }
    }
  }
}
