@import '../../../global';

.FormSwitch {
  padding: 0 !important;
  height: 22px !important;
  width: 40px !important;

  .MuiIconButton-root:hover {
    background: none !important;
  }

  .MuiSwitch-switchBase {
    padding: 0;

    &.Mui-checked {
      transform: translateX(18px);
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiSwitch-thumb {
    box-shadow: none;
    background-color: $black1_opacity50;
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-left: 3px;
  }

  .MuiSwitch-switchBase.Mui-checked {
    & + .MuiSwitch-track {
      opacity: 1;
    }

    .MuiSwitch-thumb {
      background: $black1;
    }
  }

  .MuiSwitch-track {
    background: white;
    min-width: 40px;
    max-width: 40px;
    height: 22px;
    border-radius: 13px;
    opacity: 1;
  }
}
