@import '../../../../global';

.LayoutHeaderMenu {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__list {
    display: flex;
    align-items: center;

    &-overlay {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      flex-direction: column;
      align-items: flex-end;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $overlay;
      transition: all 200ms ease-in;
      z-index: 1;
    }

    @include mobile {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      flex-direction: column;
      align-items: flex-end;
      padding: 82px 24px 28px;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(#1c222d, #1f2939);
      transition: all 200ms ease-in;
      z-index: 2;
    }

    &--isLightMode {
      @include mobile {
        background: linear-gradient(#ffffff, #eceef3);
      }
    }

    &--isOpen {
      opacity: 1;
      visibility: visible;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0.5px;
    cursor: pointer;

    &--green {
      color: $green;
      font-family: $bold;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: $green;
        border-radius: 4px;
        margin-right: 8px;
      }
    }

    &:not(:last-child) {
      margin-right: 18px;

      @include mobile {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  &__return {
    text-decoration: underline;
    opacity: 0.5;
    margin-right: 20px;
    white-space: nowrap;

    &:hover {
      opacity: 0.6;
    }
  }

  &__avatar {
    height: 14px;
    width: 14px;
    margin-right: 6px;
    border-radius: 50%;
  }

  &__hideDesktop {
    display: none;

    @include mobile {
      display: flex;
    }
  }

  &__hideMobile {
    display: flex;

    @include mobile {
      display: none;
    }
  }
}

.LayoutHeaderMenuBurger {
  flex-direction: column;
  justify-content: space-around;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  position: relative;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 3;

  &--withDot {
    &::before {
      position: absolute;
      top: 0;
      right: -6px;
      z-index: 2;
      content: '';
      width: 12px;
      height: 12px;
      background: $green;
      border-radius: 6px;
      transition: all 200ms linear;
    }
  }

  &:focus {
    outline: none;
  }

  div {
    position: absolute;
    right: 0;
    height: 2px;
    transition: all 200ms linear;
    transform-origin: right;
    background-color: white;

    &:first-child {
      width: 20px;
      right: 0;
      top: 5px;
    }

    &:nth-child(2) {
      width: 28px;
    }

    &:nth-child(3) {
      width: 24px;
      bottom: 5px;
    }
  }

  &--isLightMode {
    div {
      background-color: $black1;
    }
  }

  &--isOpen {
    &::before {
      opacity: 0;
    }

    div {
      width: 28px !important;
    }
    :first-child {
      transform: rotate(-45deg);
      top: 3px !important;
    }

    :nth-child(2) {
      opacity: 0;
    }

    :nth-child(3) {
      transform: rotate(45deg);
      bottom: 3px !important;
    }
  }
}
