@import '../../../global';

.FormCheckbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  .MuiIconButton {
    &-root {
      padding: 0px;
      color: $gray3;
      background: white;
      border-radius: 0;
    }
    &-label {
      width: 14px;
      height: 14px;
    }
  }

  .Mui-checked {
    color: $gray1;
  }

  &__label {
    margin-left: 10px;
    font-family: $bold;
    color: $black1;
    font-size: 12px;
    letter-spacing: 0.6px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
