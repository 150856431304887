@import '../../../global';

.NFTPageItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 84px 36px;
  width: 100%;
  max-width: 1088px;

  @include mobile {
    box-sizing: border-box;
    padding: 0 14px 36px;
  }

  &__container {
    display: flex;
    width: 100%;
    box-sizing: border-box;

    @include mobile {
      flex-direction: column;
      padding: 0 12px;
    }
  }

  @include mobile {
    flex-direction: column;
  }

  &.xtz {
    .img {
      width: 100%;
    }
  }

  &__collectionName {
    display: flex;
    align-items: center;
    font-family: $italic;
    font-size: 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff54;

    &__info {
      margin-left: 5px;
    }

    &--isLightMode {
      border-bottom-color: $black1_opacity33;
    }
  }

  &__collectionDescription {
    display: none;
    background-color: $descriptionBg_dark;
    font-size: 12px;
    letter-spacing: 0.5px;
    padding: 10px 20px;
    margin-top: 10px;
    margin: 0 0px;
    text-align: left;
    border-bottom: 1px solid #ffffff54;

    a {
      text-decoration: underline;
    }

    &--isLightMode {
      border-bottom-color: $black1_opacity33;
      background-color: $descriptionBg_light;
    }

    &--active {
      display: initial;
    }
  }

  &__main {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 47%;
    flex-shrink: 0;

    @include mobile {
      width: 100%;
    }

    &__media-container {
      position: relative;
      display: flex;
      width: 100%;
    }

    iframe {
      border: none;
      min-height: 500px;
      width: 100%;
      box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.1);
    }
    video {
      height: intrinsic;
      max-width: 100%;
      width: 100%;
    }
    img:not(.NFTPageItem__main__fullscreen) {
      height: intrinsic;
      max-width: 100%;
      width: 100%;
    }
    model-viewer {
      height: intrinsic;
      height: 500px;
      max-width: 100%;
      width: 100%;
    }

    &.flow {
      background-color: #333a48;

      @include mobile {
        background-color: initial;
      }
    }

    &__fullscreen {
      height: 25px;
      position: absolute;
      bottom: 12px;
      right: 12px;
      pointer-events: none;
    }
  }

  &__sub {
    display: flex;
    flex-direction: column;
    margin-left: 6%;
    flex-grow: 1;

    @include mobile {
      width: 100%;
      margin-left: 0;
      max-width: none;
    }
  }

  &__name {
    display: flex;
    font-size: 32px;
    font-family: $bold-italic;
    line-height: 1.25em;
    padding: 5px 0;
    overflow-wrap: anywhere;
    align-items: flex-start;

    img {
      margin-top: 9px;
    }

    @include mobile {
      font-size: 24px;

      img {
        margin-top: 5px;
      }
    }
  }

  &__username {
    font-family: $italic;

    @include mobile {
      max-width: 85vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__description {
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 15px;
    max-width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5em;
    letter-spacing: 0.5px;

    &__break {
      margin: 10px 0;
    }

    strong {
      font-family: $bold;
      font-weight: initial;
    }

    &__header {
      padding-bottom: 10px;
    }
  }

  &__traits {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 500;

    .trait {
      padding-bottom: 5px;
      text-transform: capitalize;
      background-color: $descriptionBg_dark;
      border-radius: 18px;
      padding: 5px 10px;
      display: flex;
      margin-right: 10px;
      margin-bottom: 10px;

      .semi-bold {
        letter-spacing: 1.5px;
      }

      &__value {
        margin-left: 5px;

        &--noCapitalize {
          text-transform: initial;
        }
      }
    }

    &--isLightMode {
      .trait {
        background-color: $descriptionBg_light;
      }
    }
  }

  &__url {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    letter-spacing: 0.5px;
    font-size: 12px;

    img {
      width: 24px;
      margin-right: 10px;
    }

    span {
      font-family: $bold;
      margin-right: 7px;
    }

    a {
      text-decoration: underline;
    }
  }
}

.NFTPageBreadcrumb {
  display: flex;
  max-width: calc(100% - 50px);
  overflow-x: hidden;
  margin-bottom: 32px;
  font-family: $light-italic;
  font-size: 16px;
  white-space: nowrap;

  span {
    margin: 0 4px;
  }

  a:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  a:hover {
    text-decoration: underline;
  }

  @include mobile {
    margin-bottom: 20px;
  }
}

.NFTPageNotes {
  margin-top: 20px;

  &__header {
    font-family: $bold;
    font-size: 12px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    span {
      font-family: $default;
      letter-spacing: 0.5px;
      text-decoration: underline;
      text-transform: initial;
      cursor: pointer;
      display: none;
    }
  }

  &__text {
    background-color: $descriptionBg_dark;
    font-size: 12px;
    letter-spacing: 0.5px;
    padding: 18px;
    margin-top: 5px;
    white-space: pre-line;

    &--isLightMode {
      background-color: $descriptionBg_light;
    }
  }
}
