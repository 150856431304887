@import '../../global';

.CollectionPage {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 1104px;
  max-width: 100%;
  padding: 44px 0;
  box-sizing: border-box;

  @include mobile {
    padding: 38px 14px;
  }

  &__name-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;

    a {
      display: flex;
    }

    &__username {
      font-family: $light-italic;
      font-size: 16px;
      letter-spacing: 0.5px;

      &:hover {
        text-decoration: underline;
      }
    }

    &__name {
      margin: 0;
      letter-spacing: 0.5px;
      text-align: left;
      width: 100%;
      white-space: nowrap;
      display: flex;
      font-family: $bold-italic;
      text-transform: uppercase;
      font-size: 32px;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }

  &__description {
    background-color: $descriptionBg_dark;
    font-size: 12px;
    padding: 10px 20px;
    text-align: left;
    white-space: pre-line;

    &--isLightMode {
      background-color: $descriptionBg_light;
    }
  }

  &__list {
    width: 100%;
    padding-top: 36px;
    box-sizing: border-box;

    @include mobile {
      padding: 36px 12px 0;
    }
  }
}
