@import '../../../global';

.FormInput {
  @extend .input-text;
  padding: 0 20px;
  border-radius: 18px;
  height: 48px;
  flex-grow: 1;

  &:focus {
    outline: none;
  }

  border: 1px solid #979797;
}
