.NFTMedia {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  position: relative;

  &__cover {
    position: absolute;
    height: 75%;
    width: 100%;
    z-index: 1;
    display: block;
  }

  img,
  video {
    max-height: 100%;
    max-width: 100%;
  }
}
