.form {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  color: black;

  select {
    padding: 0 20px;
    border-radius: 18px;
    height: 48px;
    flex-grow: 1;
    border: 1px solid #979797;
    outline: none;

    &::placeholder {
      color: gray;
    }
  }

  &__add-nft {
    margin-bottom: 12px;
    &__header {
      font-size: 12px;
      line-height: 17px;
      font-family: "Poppins-Bold";
      text-transform: uppercase;
      letter-spacing: 2.5px;
      padding-bottom: 6px;
    }
  }

  &__validation {
    font-size: 12px;
    color: red;
    margin-top: 10px;
    margin-right: 60px;

    &.success {
      color: green;
    }
  }
}
