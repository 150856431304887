@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: local('Poppins-Italic'),
    url(./fonts/Poppins-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src: local('Poppins-LightItalic'),
    url(./fonts/Poppins-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: local('Poppins-BoldItalic'),
    url(./fonts/Poppins-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Future-PT-BoldItalic';
  src: local('FuturaPTBoldOblique'),
    url(./fonts/FuturaPTBoldOblique.otf) format('opentype');
}

html:-ms-fullscreen {
  width: 100%; /* needed to center contents in IE */
}

img:-webkit-full-screen {
  width: initial !important;
  max-width: initial !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.bold {
  font-family: Poppins-Bold;
  letter-spacing: 3.43px;
}

.semi-bold {
  font-family: Poppins-SemiBold;
  letter-spacing: 1.5px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

body {
  margin: 0 auto;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-highlight {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4b5464;
  border-radius: 18px;
  height: 24px;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  padding: 10px 18px;
  max-width: 203px;
  font-family: Poppins-Bold;
  letter-spacing: 3.43px;
  cursor: pointer;
  border: 0;
}

.button.green {
  background-color: #d0ff00;
  color: black;
  padding: 10px 10px;
}

.button.green.center {
  margin: 0 auto;
}

.button.disabled {
  color: white;
  opacity: 0.5;
  cursor: initial;
}

.button.red {
  background-color: #ff0044;
  color: white;
}

.button.gray {
  background-color: gray;
  color: white;
}

.cancel {
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

#file-upload {
  display: none;
}
