@import '../../global';

.LandingPage {
  display: flex;
  flex-direction: column;
  width: 100%;

  b {
    font-family: $bold-italic;
  }

  &__footer {
    a {
      color: white;
      text-decoration: underline;
    }
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  .button {
    width: 128px;
    &.wide {
      width: 227px;
      max-width: 227px;
    }
    &._258 {
      width: 258px;
      max-width: 258px;
      margin-top: 12px;
    }
  }

  &__divider {
    display: none;
    background-color: white;
    img {
      max-width: 100vw;
      background-color: white;
    }
    &.flipped {
      margin-top: -1.6vw;
      transform: rotateX(180deg);
      background-color: transparent;
    }
  }

  &__section {
    display: flex;
    justify-content: center;

    &.padding {
      padding: 56px;
      @include mobile {
        padding: 40px;
      }
    }

    &.hide {
      display: none;
    }

    &.mobile {
      display: none;
      @include mobile {
        display: initial;
        width: 100%;
        &.white {
          margin-top: -1px;
        }
      }
    }

    &__contact {
      border-radius: 32px;
      background-color: rgba(255, 255, 255, 0.1);
      max-width: 476px;
      padding: 30px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 50px;

      @include mobile {
        max-width: 220px;
      }

      .button {
        margin-top: 25px;
      }
    }

    @include mobile {
      flex-direction: column;
    }

    &__featured-header {
      // border-bottom: 1px solid gray;
      font-family: $bold;
      font-size: 12px;
      letter-spacing: 2.5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      margin-left: 12px;

      @include mobile {
        display: none;
      }
    }

    &__featured-profiles {
      display: flex;
      flex-direction: row;

      @include mobile {
        display: none;
      }

      &__profile {
        img {
          margin-bottom: 0 !important;
        }
        &__footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;

          font-family: $italic;
          font-size: 16px;
          letter-spacing: 0.5px;

          &__link {
            font-family: $default;
            font-size: 12px;
            text-decoration: underline;
          }
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 50px 0;
      @include mobile {
        // padding: 0 36px;
      }
    }

    &.column {
      flex-direction: column;
      padding: 60px 0;
      max-width: 770px;
      margin: 0 auto;
      @include mobile {
        margin: 0;
      }
      img {
        margin: 20px 0;
      }
    }

    &.wide {
      max-width: initial;
    }

    &.white {
      background-color: white;
      color: black;
      text-align: center;
      padding: 88px 0 0;
    }

    &__inner {
      max-width: 770px;
      text-align: center;

      @include mobile {
        padding: 0 36px;
      }

      &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 75px;
        margin-top: 40px;

        @include mobile {
          padding: 0;
        }

        &__header {
          font-size: 32px;
          font-family: $bold-italic;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        &__checklist {
          margin-bottom: 12px;
          div {
            display: flex;
            margin-left: 25px;
            font-size: 16px;
            letter-spacing: 0.5px;
            font-family: $italic;
            margin-bottom: 10px;

            @include mobile {
              margin-left: 0;
              font-size: 14px;
              text-align: left;
              margin-right: 10px;
            }

            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  &__header {
    letter-spacing: 0.75px;
    font-size: 24px;

    &.primary {
      @include mobile {
        display: none;
      }
      &.mobile {
        display: none;
        @include mobile {
          display: flex;
        }
      }
    }

    &.padding {
      font-size: 32px;
      padding: 0 35px;
      letter-spacing: 1px;
      @include mobile {
        padding: 0;
      }
    }
    &.center {
      text-align: center;
      margin: 18px 0;
    }
  }
  &__subheader {
    &.italic {
      font-family: $italic;
    }
    font-size: 16px;
    margin: 8px 20px 8px 0;
    max-width: 350px;
    letter-spacing: 0.5px;

    &.white {
      max-width: initial;
    }

    &__cryptos {
      margin: 10px 0 30px;
    }
  }
  &__subtext {
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }
  &__button-container {
    display: flex;
    flex-direction: column;

    &.top-spacing {
      margin-top: 28px;
    }

    &.center {
      align-items: center;
    }

    &__logos-container {
      margin-bottom: 20px;

      img {
        margin-right: 10px;
        width: 24px;
      }
    }

    &__subtext {
      margin-top: 20px;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: rgba(255, 255, 255, 0.5);

      &.black {
        color: black;
      }
    }
  }
  &__image {
    margin-top: -20px;
    @include mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0;

      img {
        width: 75vw;
      }
    }
    &__desktop {
      display: initial;
      max-width: 100vw;
      &.png {
        width: 45vw;
      }
    }
    &__mobile {
      display: none;
      &.margin-left {
        margin-left: -26px;
      }
    }
    @include mobile {
      &__desktop {
        display: none;
      }
      &__mobile {
        display: initial;
        width: 75vw;
      }
    }
  }
}
