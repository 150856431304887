@import '../../../global';

.LayoutHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 20px 36px 0;
  width: 100%;
  box-sizing: border-box;

  @include mobile {
    height: 62px;
    padding: 14px 24px 0 16px;

    > .LayoutHeaderThemeSwitch {
      display: none;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 3;

    &__ggallery {
      margin-top: 4px;
      margin-left: 4px !important;
      img {
        height: 32px;
      }
    }

    &__rizzle {
      img {
        width: 28px;
        border-radius: 50%;
      }
    }

    span {
      font-family: 'Future-PT-BoldItalic';
      font-size: 24px;
      line-height: 1;
      letter-spacing: 0.5px;
      margin-left: 12px;
    }
  }
}
