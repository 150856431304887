@import '../../../global';

.ProfilePageNftsLoadingModal {
  &__header {
    @extend .small-bold-text;
    padding-bottom: 14px;
  }

  &__bullets {
    div {
      display: flex;
      align-items: center;
      padding-bottom: 12px;
    }
    strong {
      font-family: $bold;
      font-size: 32px;
      letter-spacing: 0.89px;
      padding-right: 10px;
      line-height: 24px;
    }
    span {
      color: $black1;
      font-size: 16px;
      letter-spacing: 0.44px;
      line-height: 24px;
    }
  }

  &__note {
    color: black;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 18px;
    padding-top: 4px;
    padding-bottom: 6px;
  }
}
