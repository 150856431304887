@import '../../global';

.Layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  &__wrapper {
    background-color: $black1;
    color: white;

    &--isLightMode:not(.Layout__wrapper--isLandingPage) {
      background-color: white;
      color: $black1;
    }

    &--isLandingPage {
      width: 100%;
      padding: 0 !important;

      .Layout {
        max-width: 100%;

        &__body {
          width: 100%;
          padding: 0;
        }
      }
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    padding: 0 36px;
    width: 1394px;
    max-width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;

    @include mobile {
      padding: 0 18px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 24px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
}
