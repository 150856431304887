@import '../../../global';

.FormInputContainer {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-bottom: 10px;

  &__label {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    padding-bottom: 6px;

    span {
      @extend .small-bold-text;
      margin-right: 20px;
    }
  }

  &__note {
    margin-right: 10px;
    font-size: 10px;
    letter-spacing: 0.38px;
    color: $black1;
    opacity: 0.5;
  }

  &__validation {
    max-width: 100%;
    word-break: break-word;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Old versions of Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    &:not(.valid):not(.invalid) {
      color: $black1;
      opacity: 0.5;
    }

    &.valid {
      color: green;
    }

    &.invalid {
      color: red;
    }
  }
}
