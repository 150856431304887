@import '../../global';

.NFTPage {
  padding: 44px 0 8px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mobile {
    padding-top: 38px;
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    position: relative;

    img {
      cursor: pointer;
    }

    iframe {
      max-width: 100%;
    }

    &__arrow {
      position: absolute;
      height: 42px;
      top: 78px;

      img {
        max-height: 100%;
      }

      @include mobile {
        top: 2px;
        height: 14px;
        min-width: initial;
      }

      &--prev {
        left: 12px;

        @include mobile {
          left: initial;
          right: 42px;
        }
      }

      &--next {
        right: 12px;

        @include mobile {
          right: 14px;
        }
      }
    }
  }

  &__qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    &__container {
      background-color: #fff;
      padding: 12px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      display: flex;
      cursor: pointer;
    }

    &__header {
      margin-bottom: 15px;
    }

    &__title {
      font-family: $bold;
    }

    img {
      max-width: 100px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
    }
  }

  .audio {
    display: flex;
    flex-direction: column;

    audio {
      margin-top: 10px;
      width: 100%;
    }
  }
}
