@import '../../../global';

.NFTsTablePagination {
  max-width: 100%;

  .MuiTablePagination {
    @extend .table-default-text;
    max-width: 100%;

    &-caption {
      @extend .table-default-text;

      &:not(:nth-child(2)) {
        display: none;
      }
    }

    &-input {
      margin-left: 0;
    }

    &-select {
      @extend .table-default-text;
      padding-left: 4px;
      padding-top: 4px;
      padding-right: 16px !important;

      &:focus {
        background: transparent;
      }
    }

    &-selectRoot {
      margin: 0;
    }

    &-selectIcon {
      @extend .table-default-text;
      top: calc(50% - 8px);
      font-size: 16px;
    }

    &-toolbar {
      min-height: 49px;
      padding: 0 8px;

      @include mobile {
        flex-wrap: wrap-reverse;
        justify-content: center;
      }
    }

    &-spacer {
      display: none;
    }
  }
}

.NFTsTablePaginationActions {
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  @extend .table-default-text;

  @include mobile {
    width: 100%;
    justify-content: center;
    margin: 20px 0 12px;
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
  }

  .MuiButtonBase-root {
    font-size: 10px;
    padding: 0;
    color: $black1;

    &.Mui-disabled {
      color: $black1_opacity50;
    }

    .MuiSvgIcon-root {
      width: 21px;
      height: 21px;
    }
  }
}
