@import '../../global';

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gray1;
  border-radius: 18px;
  height: 48px;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  padding: 0 24px;
  font-family: $bold;
  letter-spacing: 2.5px;
  cursor: pointer;
  border: 0;
  white-space: nowrap;

  &.green {
    background-color: $green;
    color: black;
  }

  &.red {
    background-color: $red;
    color: white;
  }

  &.gray {
    background-color: gray;
    color: white;
  }

  &:disabled {
    background: $gray1;
    color: white;
    opacity: 0.5;
    cursor: initial;
  }
}
