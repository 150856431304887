@import '../../global';

.CustomModal {
  display: flex;
  flex-direction: column;
  color: $black1;
  background-color: white;
  border-radius: 36px;
  padding: 38px 42px;
  width: 458px;
  box-sizing: border-box;
  outline: none;
  margin: 98px auto;

  &--scrollable {
    max-height: calc(100vh - 186px);

    .CustomModal__body {
      display: flex;
      flex-direction: column;
      overflow: auto;

      .modal-boxed-text {
        margin-top: 0 !important;
        max-height: 104px;
        overflow: auto;
        flex-shrink: 0;
        box-sizing: border-box;
      }
    }
  }

  @include mobile {
    max-width: calc(100% - 48px);
    max-height: calc(100vh - 76px);
    // margin: 58px auto;
    margin: 38px auto;
    padding: 28px 30px 22px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 18px;
  }

  &__title {
    font-size: 32px;
    line-height: 36px;
    font-family: $bold-italic;

    @include mobile {
      font-size: 28px;
    }
  }

  form {
    margin-left: 8px;
  }

  &__buttons {
    margin-left: 4px;
  }

  .modal-boxed-text {
    @extend .box;
    font-family: $medium;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 16px;
    padding: 15px 12px;
    word-break: break-word;
    margin-top: -6px;
    margin-bottom: 16px;

    &.disabled {
      color: $black1_opacity50;
      justify-content: center;
      margin-bottom: 6px;
      text-align: center;
      font-family: $italic;
    }
  }

  .modal-confirmation-title {
    @extend .small-bold-text;
    margin-bottom: 6px;
  }

  .modal-confirmation-text {
    font-size: 11px;
    letter-spacing: 0.33px;
    line-height: 17px;
    margin-right: 5px;
  }
}

.ReactModal {
  &__Overlay {
    overflow-y: auto;
    z-index: 3;
    background-color: $overlay !important;
  }
}
