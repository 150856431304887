$mobile-width: 800px;

$black1: #1c222d;
$black1_opacity50: #1c222d80;
$black1_opacity33: #1c222d54;
$black1_opacity25: #1c222d40;
$gray1: #4b5464;
$gray2: #ecedee;
$gray3: #979797;
$green: #d0ff00;
$red: #ff0044;
$descriptionBg_dark: #333a48;
$descriptionBg_light: #f1f2f3;
$overlay: rgba(0, 0, 0, 0.5);

$default: 'Poppins';
$medium: 'Poppins-Medium';
$bold: 'Poppins-Bold';
$italic: 'Poppins-Italic';
$light-italic: 'Poppins-LightItalic';
$bold-italic: 'Poppins-BoldItalic';

.small-bold-text {
  font-size: 12px;
  line-height: 17px;
  font-family: $bold;
  text-transform: uppercase;
  letter-spacing: 2.5px;
}

.box {
  background-color: $gray2;
  padding: 15px 16px;
  display: flex;
  flex-direction: row;
  color: $black1;
}

.input-text {
  color: $black1;
  font-size: 12px;
  font-family: $default;
}

.table-bold-text {
  color: $black1;
  font-family: $bold;
  font-size: 12px;
  letter-spacing: 0.6px;
  line-height: 16px;
  word-break: break-word;
}

.table-default-text {
  color: $black1;
  font-family: $default;
  font-size: 11px;
  letter-spacing: 0.33px;
  line-height: 17px;
  word-break: break-word;
}

@mixin mobile {
  @media screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin home_1 {
  @media screen and (max-width: 675px) {
    @content;
  }
}

@mixin home_2 {
  @media screen and (max-width: 975px) {
    @content;
  }
}

@mixin home_3 {
  @media screen and (max-width: 1300px) {
    @content;
  }
}

body {
  font-family: $default;
}
