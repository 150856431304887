@import '../../global';

.ProfilePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  &__button {
    margin-top: 6px;
    margin-bottom: 36px;

    @include mobile {
      margin-top: 8px;
    }
  }

  &__collector-creator-toggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 3px 3px;
    background-color: $gray1;
    border-radius: 19px;
    max-width: 257px;

    &__button {
      padding: 7px 28px;
      font-size: 12px;
      max-width: 124px;
      cursor: pointer;
      color: white;

      &.active {
        background-color: $green;
        color: $black1;
        font-family: $bold;
        border-radius: 19px;
      }
    }
  }
}
