.ModalButtons {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;

  > * {
    margin-top: 8px;
    margin-bottom: 8px;
    max-width: 188px;
  }

  .TextButton {
    margin-left: 24px;
  }
}
