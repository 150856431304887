@import '../../global';

.AddAddressModalAddresses {
  @extend .box;
  flex-direction: column;
  margin-bottom: 16px;

  > div {
    padding-top: 10px;

    &:first-child {
      padding-top: 0;
    }
  }

  &__subheader {
    color: $black1;
    font-family: $bold;
    font-size: 12px;
    padding-bottom: 4px;
  }

  &__address {
    display: flex;
    align-items: center;
    color: $black1;
    font-size: 12px;
    line-height: 21px;

    span {
      letter-spacing: 1px;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-touch-callout: text; /* iOS Safari */
      -webkit-user-select: text; /* Safari */
      -khtml-user-select: text; /* Konqueror HTML */
      -moz-user-select: text; /* Old versions of Firefox */
      -ms-user-select: text; /* Internet Explorer/Edge */
      user-select: text; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }

    &__value {
      font-size: 11px;
    }

    &__remove {
      cursor: pointer;
      font-size: 20px;
      line-height: 21px;
      flex-shrink: 0;
      margin-top: 2px;
    }
  }
}
