@import '../../../global';

.NFTsTableToolbar {
  display: flex;
  padding: 0px 0px 12px 0;
  min-height: initial;
  align-items: center;

  @include mobile {
    // flex-direction: row-reverse;
    padding: 0 0 12px;
  }

  > img {
    cursor: pointer;
  }

  &__search {
    display: flex;
    align-items: center;
    background: $gray2;
    border-radius: 6px;
    flex-grow: 1;
    padding: 0 9px 0 14px;
    margin-right: 12px;

    @include mobile {
      margin-right: 12px;
      margin-left: 0;
    }

    input {
      @extend .table-default-text;
      border: none;
      outline: none;
      background: transparent;
      height: 36px;
      flex-grow: 1;

      &::placeholder {
        color: $black1_opacity25;
      }
    }
  }
}
