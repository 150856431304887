@import '../../global';

.FloatingMessage {
  background-color: white;
  color: black;
  position: fixed;
  right: 100px;
  bottom: 100px;
  padding: 20px;
  border-radius: 50px;
  text-transform: uppercase;
  font-family: $bold;

  @include mobile {
    bottom: 25px;
  }
}
