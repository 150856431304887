@import '../../../global';

.ProfilePageCollection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 36px;
  box-sizing: border-box;

  @include mobile {
    padding: 0 23px;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    font-family: $bold;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #ffffff54;
    padding-bottom: 10px;

    &--isLightMode {
      border-bottom-color: $black1_opacity33;
    }

    a {
      display: flex;
    }
  }

  &__collapse-button {
    cursor: pointer;
    height: 17px;
    overflow-x: hidden;
    display: flex;

    span {
      @extend .small-bold-text;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__caret {
    position: absolute;
    cursor: pointer;
    left: -24px;
    top: 9px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
  }

  &__link {
    margin-left: 6px;
  }

  .NFTsList {
    margin-top: 28px;
  }
}
