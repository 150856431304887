@import '../../global';

.ProfileHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 28px;
  margin-bottom: 12px;
  max-width: 100%;

  @include mobile {
    margin-top: 18px;
  }

  &__img {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    margin-bottom: 9px;

    &__clickable {
      cursor: pointer;
    }
  }

  &__username-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    &--withButton {
      max-width: calc(100vw - 48px);
    }

    @include mobile {
      margin-bottom: 6px;
    }
  }

  &__username {
    font-family: $bold-italic;
    font-size: 48px;
    letter-spacing: 1.33px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 38px;
    white-space: nowrap;
    line-height: 67px;

    @include mobile {
      padding: 0 30px;
      font-size: 32px;
      letter-spacing: 1px;
      line-height: 45px;
    }
  }

  &__button {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    cursor: pointer;
  }

  &__error {
    font-size: 10px;
    font-family: $default;
    color: red;
    margin-top: 8px;
  }

  &__bio {
    font-size: 12px;
    letter-spacing: 0.5px;
    opacity: 0.5;
    display: flex;
    width: 536px;
    padding: 0 6px;
    box-sizing: border-box;
    justify-content: center;
    max-width: 100%;

    @include mobile {
      margin-bottom: 10px;
    }
  }
}

.AdminPage {
  .ProfileHeader {
    @include mobile {
      margin-top: 38px;
    }
  }
}
