@import '../../../../global';

.LayoutHeaderThemeSwitch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 64px;
  height: 28px;

  @include mobile {
    position: static;
    transform: initial;
    margin-top: 4px;
  }

  img {
    cursor: pointer;
    height: 28px;
    width: 28px;
    opacity: 0.25;

    &:hover {
      opacity: 1;
    }
  }
}
